<template>
  <vx-card :title="title">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date</label>
        <vs-input
          class="w-full"
          v-model="date"
          type="date"
          v-validate="'required'"
        />
      </div>
    </div>
    <select-territory @data="setTerritoryId"></select-territory>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <span>Collection Type</span>
        <multiselect
          v-model="collectionTypSelected"
          :options="collectionTypeOptions"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="true"
          :multiple="true"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col flex items-center justify-items-start gap-2">
        <vs-button
          @click="onClickSearch"
          size="small"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Search</vs-button
        >
        <vs-button
          @click="onClickBtnConfirmToApproved"
          size="small"
          color="success"
          icon-pack="feather"
          icon="icon-check"
          title="Approved"
          >Confirm To Released</vs-button
        >
      </div>
    </div>

    <div class="vx-row">
      <vs-tabs>
        <vs-tab label="Draft" @click="onClickTab('released')">
          <div class="con-tab-ejemplo">
            <table-realeased
              @data-print="onClickPrint"
              ref="table_released"
              :territoryID="this.territorryId"
              :date="this.date"
            ></table-realeased>
          </div>
        </vs-tab>
        <vs-tab label="Wait Approval" @click="onClickTab('wait_approval')">
          <div class="con-tab-ejemplo">
            <table-wait-approval
              @data-print="onClickPrint"
              ref="table_wait_approval"
              :territoryID="this.territorryId"
              :date="this.date"
            ></table-wait-approval>
          </div>
        </vs-tab>
        <vs-tab label="Released" @click="onClickTab('approved')">
          <div class="con-tab-ejemplo">
            <table-approved
              @data-print="onClickPrint"
              ref="table_approved"
              :date="this.date"
              :territoryID="this.territorryId"
            ></table-approved>
          </div>
        </vs-tab>
        <!-- <vs-tab disabled label="Processed">
          <div class="con-tab-ejemplo">Comming soon</div>
        </vs-tab> -->
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import TableRealeased from "./_tabs/table-released.vue";
import TableWaitApproval from "./_tabs/table-wait-approval.vue";
import TableApproved from "./_tabs/table-approved.vue";
import SelectTerritory from "./select-territory.vue";

export default {
  components: {
    TableApproved,
    TableWaitApproval,
    TableRealeased,
    SelectTerritory,
  },
  data() {
    return {
      title: "Manage Collection Plan (CIT)",
      date: null,
      // select territory
      selectedTerritory: null,
      optionTerritory: [],
      territorryId: null,
      territoryCode: null,
      collectionTypeOptions: [
        { name: "On Route", value: 1 },
        { name: "Off Route", value: 2 },
        { name: "COD Driver", value: 3 },
        { name: "COD Canvas", value: 4 },
        { name: "COD LCO", value: 5 },
      ],
      collectionTypSelected: null,
    };
  },
  mounted() {},
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    onClickTab(type) {
      if (this.date == null) {
        return;
      }

      let params = {
        date: this.date,
      };
      if (this.territorryId != null) {
        params.territory_id = this.territorryId;
      }

      switch (type) {
        case "released":
          params.status = 1;
          params.is_released_list = true;
          // set timeout to wait for load component
          setTimeout(() => {
            this.$refs.table_released.getData(params);
          }, 100);
          break;
        case "wait_approval":
          params.is_waiting_list = true;
          setTimeout(() => {
            this.$refs.table_wait_approval.getData(params);
          }, 100);
          break;
        case "approved":
          params.status = 1;
          params.is_approved_list = true;
          setTimeout(() => {
            this.$refs.table_approved.getData(params);
          }, 100);
          break;
      }
    },
    setTerritoryId(id, code) {
      this.territorryId = id;
      this.territoryCode = code;
    },
    onClickBtnConfirmToApproved() {
      let collection_plan_ids = this.$refs.table_released.selected_rows.map(
        (item) => item.id
      );
      if (collection_plan_ids.length == 0) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select collection plan",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();

      this.$http
        .post("api/sfa/v1/collection-plan/multiple-approval", {
          ids: collection_plan_ids,
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Collection plan has been approved",
              color: "success",
              position: "top-right",
            });

            this.$refs.table_released.getData({
              status: 1,
              data: this.date,
              territory_id: this.territorryId,
              is_released_list: true,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              color: "danger",
              position: "top-right",
            });
          }
        });
    },
    onClickSearch() {
      if (this.date == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select date",
          color: "warning",
          position: "top-right",
        });
        return;
      }
      let params = {
        status: 1,
        date: this.date,
        territory_id: this.territorryId,
        length: 10,
        collection_types: this.collectionTypSelected.map((item) => item.value),
      };

      if (this.$refs.table_released) {
        params.is_released_list = true;
        this.$refs.table_released.getData(params);
      }

      if (this.$refs.table_wait_approval) {
        params.is_waiting_list = true;
        console.log(params);
        this.$refs.table_wait_approval.getData(params);
      }

      if (this.$refs.table_approved) {
        params.is_approved_list = true;
        this.$refs.table_approved.getData(params);
        // force update
        this.$forceUpdate();
      }

      this.$vs.notify({
        title: "Success",
        text: "Search data success",
        color: "success",
        position: "top-right",
      });
    },
    onSearchTerritory(query) {
      let company_id = this.$store.state.user.currentUser.company_id;
      this.$http
        .get(
          "api/sfa/v1/territory?company_id=" + company_id + "&search=" + query
        )
        .then((response) => {
          this.optionTerritory = response.data.records;
        });
    },
    onClickPrint(id, type) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_${currentDate}`;
      let ids = "collection_plan_ids[]=" + id;
      if (type == "excel") {
        type = "is_export_excel=true";
      }
      moment(this.startDate).format("YYYY-MM-DD") +
        this.$http
          .get(`api/sfa/v1/collection-plan/multiple-export?${ids}&${type}`, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([resp]));
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;

              fileLink.setAttribute("download", fileTitle + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          });
    },
    async fetchTerritory() {
      let company_id = this.$store.state.user.currentUser.company_id;
      const response = await this.$http.get(
        "api/sfa/v1/territory?company_id=" + company_id
      );

      this.optionTerritory = response.data.records;
    },
    customLabelTerritory({ name, code }) {
      return `${code} - ${name}`;
    },
  },
};
</script>
